// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import getConfig from '../../appConfigUtils';
import { settingsRoutes } from '../../routers/route-names';
import { LinkWithQuery } from '../../utils/routingHelpers';
import './NavigationMenu.less';

interface Props {
  styles?: { [key: string]: string };
}

enum SettingMenuItemKeys {
  Agent = 'agent',
  Appearance = 'appearance',
  IdentityBroker = 'identity-broker',
  Notifications = 'notifications',
  System = 'system',
  LogonEvents = 'app-termination',
  AdvancedSettings = 'advanced-settings',
}

const NavigationMenu: React.FC<Props> = ({ styles }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const history = useHistory();
  useEffect(() => {
    //This is for opening first tab of settings menu by default
    if (location.pathname === '/settings') {
      history.push(`/settings/${SettingMenuItemKeys.Agent}` + location.search);
    }
  }, [history, location]);

  return (
    <Menu
      theme="light"
      defaultSelectedKeys={[SettingMenuItemKeys.Agent]}
      selectedKeys={[location.pathname.split('/').pop() || '']}
      mode="inline"
      className="navigation"
      style={{ ...styles }}
      items={[
        {
          key: SettingMenuItemKeys.Agent,
          label: (
            <LinkWithQuery
              data-testid="settings-menu--agent"
              to={settingsRoutes.AGENT}
              tabIndex={-1}
            >
              {t('navigation.agent')}
            </LinkWithQuery>
          ),
        },
        {
          key: SettingMenuItemKeys.Appearance,
          label: (
            <LinkWithQuery
              data-testid="settings-menu--appearance"
              to={settingsRoutes.APPEARANCE}
              tabIndex={-1}
            >
              {t('navigation.appearance')}
            </LinkWithQuery>
          ),
        },
        // conditionally add menu item
        ...(getConfig().IDENTITY_BROKER_PAGE_ENABLED
          ? [
              {
                key: SettingMenuItemKeys.IdentityBroker,
                label: (
                  <LinkWithQuery
                    data-testid="settings-menu--identity-broker"
                    to={settingsRoutes.IDENTITY_BROKER}
                    tabIndex={-1}
                  >
                    {t('navigation.identity-broker')}
                  </LinkWithQuery>
                ),
              },
            ]
          : []),
        {
          key: SettingMenuItemKeys.Notifications,
          style: { display: 'none' },
          label: (
            <LinkWithQuery to={settingsRoutes.NOTIFICATIONS}>
              {t('navigation.notifications')}
            </LinkWithQuery>
          ),
        },
        {
          key: SettingMenuItemKeys.System,
          label: (
            <LinkWithQuery to={settingsRoutes.SYSTEM}>
              {t('navigation.system')}
            </LinkWithQuery>
          ),
        },
        // conditionally add menu item
        ...(getConfig().APP_TERMINATION_PAGE_ENABLED
          ? [
              {
                key: SettingMenuItemKeys.LogonEvents,
                label: (
                  <LinkWithQuery
                    data-testid="settings-menu--app-termination"
                    to={settingsRoutes.APP_TERMINATION}
                    tabIndex={-1}
                  >
                    {t('navigation.app-termination')}
                  </LinkWithQuery>
                ),
              },
            ]
          : []),
        // conditionally add menu item
        ...(getConfig().ADVANCED_SETTINGS_PAGE_ENABLED
          ? [
              {
                key: SettingMenuItemKeys.AdvancedSettings,
                label: (
                  <LinkWithQuery
                    data-testid="settings-menu--advanced-settings"
                    to={settingsRoutes.ADVANCED_SETTINGS}
                    tabIndex={-1}
                  >
                    {t('navigation.advanced-settings')}
                  </LinkWithQuery>
                ),
              },
            ]
          : []),
      ]}
    />
  );
};

export default NavigationMenu;
