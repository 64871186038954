// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { type LineConfig } from '@ant-design/plots';
import { type ColumnType } from 'antd/lib/table';
import { type Axis } from '@antv/g2plot/lib/types/axis';
import { type CardProps } from 'antd/lib/card/Card';
import clsx from 'clsx';
import { type ParseKeys } from 'i18next';
import * as utils from './utils';
import { NotImplemented, extractValueAtKey } from './utils';
import {
  AAD_GROUP_NAMES,
  ACTIVITY,
  AUTHENTICATOR,
  ENDPOINT,
  RESULT,
  TIMESTAMP,
  USER,
  USER_DISPLAY_NAME,
} from './constants';
import {
  type DashboardLineChartData,
  type DashboardData,
  type MappedDashboardData,
  type DashboardModalKey,
} from './types';
import {
  type ArrayTypeKeys,
  DashboardDataInterval,
  DashboardDataType,
} from '../../api/Dashboard/types';

import getConfig from '../../appConfigUtils';

const { SSO_DASHBOARD_DATA_ENABLED } = getConfig();

const OccurredAtTimestampColumn = 'occurred-at';
const OverallFailureCode = 'overall-failure-code';

export type TableCsvColumn<T> = ColumnType<Partial<T>> & { csvTitle: string };

export type DashboardDataCardDetail =
  | (Pick<DashboardCardConfig, 'label'> & {
      data: string;
    } & Partial<CardProps>)
  | null;

export interface ModalState<T extends DashboardModalKey = DashboardModalKey> {
  open?: boolean;
  key?: T;
  data?: MappedDashboardData[];
  columns?: Array<TableCsvColumn<MappedDashboardData>>;
}

// Configures the state for opening a modal based on Dashboard data type.
// Add additional ModalState settings for specific data types here
export function configureDashboardModalState<T extends DashboardModalKey>(
  key: T,
  data?: DashboardData,
  filter?: (d: DashboardData[T]) => DashboardData[T],
  utc = false,
): ModalState<T> {
  if (!data) {
    return { open: false };
  }
  const baseProps = { key, open: true };
  const dataSlice = (filter && filter(data[key])) || data[key];
  if (key === DashboardDataType.AUTHNS_DETAILS) {
    return {
      ...baseProps,
      data: utils.processDataDetails(
        dataSlice as DashboardData[DashboardDataType.AUTHNS_DETAILS],
        {
          utc,
          dateKeys: [OccurredAtTimestampColumn],
        },
      ),
      columns: dashboardAuthnsColumns,
    };
  } else if (key === DashboardDataType.ENROLLED_DETAILS) {
    return {
      ...baseProps,
      data: utils.processDataDetails(
        dataSlice as DashboardData[DashboardDataType.ENROLLED_DETAILS],
        {
          utc,
          dateKeys: ['timestamp'],
        },
      ),
      columns: dashboardDetailsColumns,
    };
  } else if (key === DashboardDataType.EPCS_READINESS_DETAILS) {
    return {
      ...baseProps,
      data: utils.processDataDetails(
        dataSlice as DashboardData[DashboardDataType.EPCS_READINESS_DETAILS],
        {
          utc,
          dateKeys: ['timestamp'],
        },
      ),
      columns: dashboardDetailsColumns,
    };
  } else if (key === DashboardDataType.UNIQUE_ACTIVE_USER_DETAILS) {
    return {
      ...baseProps,
      data: utils.processDataDetails(
        dataSlice as DashboardData[DashboardDataType.UNIQUE_ACTIVE_USER_DETAILS],
        {
          utc,
          dateKeys: ['timestamp'],
        },
      ),
      columns: dashboardUniqueUserWidgetColumns,
    };
  } else if (key === DashboardDataType.SSO_AUTHS_DETAILS) {
    return {
      ...baseProps,
      data: utils.processDataDetails(
        dataSlice as DashboardData[DashboardDataType.SSO_AUTHS_DETAILS],
        {
          utc,
          dateKeys: [OccurredAtTimestampColumn],
        },
      ),
      columns: dashboardSSOAuthnsColumns,
    };
  } else {
    return {
      open: false,
    };
  }
}

// Async function to resolve CSV data
// NOTE Making this async allows this function to fetch async when the time comes
export const getDashboardCsvData = async (
  data:
    | MappedDashboardData[]
    | (() => Promise<DashboardData[DashboardDataType] | undefined>),
  columns: Array<TableCsvColumn<MappedDashboardData>>,
): Promise<
  | string[]
  | {
      [key: string]: string | null | undefined;
    }[]
> => {
  if (!data || !columns || columns.length === 0) {
    return [];
  }

  // Map the table columns for easier access
  const csvColumns = columns.map(c => ({
    id: c.dataIndex as keyof MappedDashboardData,
    displayName: c.csvTitle,
    render: c.render,
  }));

  let fetchedData: MappedDashboardData[];
  if (Array.isArray(data)) {
    fetchedData = data;
  } else {
    const d = await data();
    fetchedData = utils.processDataDetails(d, {
      utc: true,
      dateKeys: ['timestamp'],
    });
  }

  if (!fetchedData) {
    return [];
  }

  return (fetchedData || []).map(d =>
    Object.values(csvColumns).reduce(
      (acc, { id, displayName, render }) => {
        let value = d[id];
        if (!value && render) {
          const rendered = render(undefined, d, 0);
          if (typeof rendered === 'string') {
            value = rendered;
          }
        }
        return {
          ...acc,
          [displayName]: `${value || ''}`,
        };
      },
      {} as Record<string, string | undefined>,
    ),
  );
};

export type DashboardCardConfig<
  K extends DashboardDataType = DashboardDataType,
> = {
  label: ParseKeys;
  index: number;
  dataKey: K;
  calculate: (data: DashboardData[K]) => string;
};

export const authnMetricsCardConfig: DashboardCardConfig<DashboardDataType.AUTHNS_METRICS>[] =
  [
    {
      label: 'dashboard.details.total-authentications',
      index: 4,
      dataKey: DashboardDataType.AUTHNS_METRICS,
      calculate: ({ keys, values }) => {
        if (!keys || !values) {
          return '';
        }

        return `${
          extractValueAtKey<ArrayTypeKeys<DashboardDataType.AUTHNS_METRICS>>(
            keys,
            'total-authns',
            values[0],
          ) || ''
        }`;
      },
    },
    {
      label: 'dashboard.details.password-less-authentications',
      index: 3,
      dataKey: DashboardDataType.AUTHNS_METRICS,
      calculate: ({ keys, values }) => {
        if (!keys || !values) {
          return '';
        }
        return `${
          extractValueAtKey<ArrayTypeKeys<DashboardDataType.AUTHNS_METRICS>>(
            keys,
            'success-passwordless-authns',
            values[0],
          ) || ''
        }`;
      },
    },
    {
      label: 'dashboard.details.epcs-authentications',
      index: 2,
      dataKey: DashboardDataType.AUTHNS_METRICS,
      calculate: ({ keys, values }) => {
        if (!keys || !values) {
          return '';
        }
        return `${
          extractValueAtKey<ArrayTypeKeys<DashboardDataType.AUTHNS_METRICS>>(
            keys,
            'epcs-authns',
            values[0],
          ) || ''
        }`;
      },
    },
    {
      label: 'dashboard.details.authentication-success-rate',
      index: 1,
      dataKey: DashboardDataType.AUTHNS_METRICS,
      calculate: ({ keys, values }) => {
        if (!keys || !values) {
          return '';
        }
        const getValue = (extractValueAtKey<
          ArrayTypeKeys<DashboardDataType.AUTHNS_METRICS>
        >).bind(null, keys);

        const success = getValue('success-authns', values[0]) || 0;
        const total = getValue('total-authns', values[0]) || 1;
        return `${((+success / (+total || 1)) * 100).toFixed(1)}%`;
      },
    },
  ];

export const ssoAuthnMetricsCardConfig: DashboardCardConfig<DashboardDataType.SSO_AUTHS_METRICS>[] =
  [
    {
      label: 'dashboard.details.single-sign-ons',
      index: 0,
      dataKey: DashboardDataType.SSO_AUTHS_METRICS,
      calculate: ({ keys, values }) => {
        if (!keys || !values || !SSO_DASHBOARD_DATA_ENABLED) {
          return '0';
        }
        return `${
          extractValueAtKey<ArrayTypeKeys<DashboardDataType.SSO_AUTHS_METRICS>>(
            keys,
            'success-authns',
            values[0],
          ) || '0'
        }`;
      },
    },
  ];

const allMetricsCardConfig = [
  ...authnMetricsCardConfig,
  ...ssoAuthnMetricsCardConfig,
];

export function configureDashboardCards(
  updateModalState: (
    key: DashboardModalKey,
    filter?: (
      d: DashboardData[DashboardModalKey],
    ) => DashboardData[DashboardModalKey],
  ) => void,
  data?: DashboardData,
  sharedProps?: Partial<CardProps>,
): DashboardDataCardDetail[] {
  const clickableClass = clsx('pointer', sharedProps?.className || '');

  return allMetricsCardConfig.map(conf => {
    const { label, index, dataKey, calculate } = conf;
    if (!data || index < 0) {
      return null;
    }

    if (dataKey in data && data[dataKey]) {
      // const dataSlice = data[dataKey].values
      const metricsData: unknown = data[dataKey];

      if (!metricsData) {
        return null;
      }

      const commonProps = {
        data: calculate(metricsData),
        label,
        className: sharedProps?.className || '',
      };

      // Labels below correspond to i18 .json labels
      switch (label) {
        case 'dashboard.details.epcs-authentications':
          return {
            ...commonProps,
            className: clickableClass,
            onClick: () => {
              updateModalState(DashboardDataType.AUTHNS_DETAILS);
            },
            'data-testid': 'epcs-authentications-widget',
          };
        case 'dashboard.details.authentication-success-rate':
          return {
            ...commonProps,
            'data-testid': 'authentication-success-widget',
          };
        case 'dashboard.details.total-authentications':
          return {
            ...commonProps,
            className: clickableClass,
            onClick: () => {
              updateModalState(DashboardDataType.AUTHNS_DETAILS);
            },
            'data-testid': 'total-authentications-widget',
          };
        case 'dashboard.details.password-less-authentications':
          return {
            ...commonProps,
            'data-testid': 'password-less-widget',
          };
        case 'dashboard.details.single-sign-ons':
          if (SSO_DASHBOARD_DATA_ENABLED) {
            return {
              ...commonProps,
              className: clickableClass,
              onClick: () => {
                updateModalState(DashboardDataType.SSO_AUTHS_DETAILS);
              },
              'data-testid': 'single-sign-ons-widget',
            };
          }
          return {
            ...commonProps,
            'data-testid': 'single-sign-ons-widget',
          };
        default:
          return null;
      }
    } else {
      return null;
    }
  });
}

//antd chart configuration
export function baseLineChartConfig(
  data: DashboardLineChartData[],
  interval: DashboardDataInterval,
): LineConfig {
  const xAxis: Partial<Record<DashboardDataInterval, Axis>> = {
    [DashboardDataInterval.LAST_24_HOURS]: {
      type: 'time',
      mask: 'HH:mm',
      tickCount: 13,
      // Need to clear the value left from other types
      tickInterval: undefined,
    },
    [DashboardDataInterval.PREVIOUS_7_DAYS]: {
      type: 'time',
      mask: 'YYYY-MM-DD HH:mm',
      // tickCount produces weird results for this case. This is why tickInterval.
      tickCount: undefined,
      tickInterval: 172_800_000, // 2 days
    },
    [DashboardDataInterval.PREVIOUS_30_DAYS]: {
      type: 'timeCat',
      mask: 'YYYY-MM-DD',
      tickCount: undefined,
      tickInterval: undefined,
    },
    [DashboardDataInterval.PREVIOUS_12_MONTHS]: {
      type: 'timeCat',
      mask: 'MMM YYYY',
      tickCount: 12,
      tickInterval: undefined,
    },
  };
  return {
    data,
    xField: 'datetime',
    yField: 'value',
    seriesField: 'category',
    color: ['#00a2a9', '#bb445a'],
    legend: {
      layout: 'horizontal',
      position: 'top-right',
    } as const,
    style: {
      height: '100%',
      width: '100%',
      background: '#fff',
    },
    yAxis: {
      // Prevent fraction ticks (e.g. 0.5) for small numbers datasets
      // if there are any values above 10, don't use tickInterval 1
      // I can't use minTickInterval - it is applicable for time only
      tickInterval: data.some(o => +o.value > 10) ? undefined : 1,
    },
    xAxis: {
      ...xAxis[interval],
      // Make ticks start at midnight and with tickInterval stay like that
      min:
        interval === DashboardDataInterval.PREVIOUS_7_DAYS
          ? data[0]?.datetime.replace(/\d\d:\d\d:\d\d/, '00:00:00')
          : undefined,
    },
  };
}

// Configuration for modal details Column names and values
export const dashboardAuthnsColumns: Array<
  TableCsvColumn<MappedDashboardData>
> = [
  {
    title: <span className="dashboard-data-header">{TIMESTAMP}</span>,
    csvTitle: TIMESTAMP,
    key: OccurredAtTimestampColumn,
    render(_value, record, _index) {
      const timestamp = ('timestamp' in record && record['timestamp']) || '';
      if (timestamp && timestamp !== NotImplemented) {
        return timestamp;
      } else {
        return (
          (OccurredAtTimestampColumn in record &&
            record[OccurredAtTimestampColumn]) ||
          ''
        );
      }
    },
  },
  {
    title: <span className="dashboard-data-header">{USER}</span>,
    csvTitle: USER,
    key: 'user',
    render(_value, record, _index) {
      const user = ('user' in record && record['user']) || '';
      if (user && user !== NotImplemented) {
        return user;
      } else {
        const displayName =
          USER_DISPLAY_NAME in record && record[USER_DISPLAY_NAME];

        if (!displayName) {
          return '';
        }
        const username = 'user-upn' in record ? ` (${record['user-upn']})` : '';
        return `${displayName}${username}`;
      }
    },
  },
  {
    title: <span className="dashboard-data-header">{ACTIVITY}</span>,
    csvTitle: ACTIVITY,
    key: 'activity',
    dataIndex: 'activity',
  },
  {
    title: <span className="dashboard-data-header">{RESULT}</span>,
    csvTitle: RESULT,
    key: 'result',
    render(_value, record, _index) {
      const result = ('result' in record && record['result']) || '';
      if (result && result !== NotImplemented) {
        return result;
      } else {
        const overallResult =
          'overall-result-type' in record && record['overall-result-type'];
        if (!overallResult) {
          return '';
        }
        if (overallResult === 'failure') {
          // Failure formatting
          const failureCode =
            (OverallFailureCode in record &&
              record[OverallFailureCode] &&
              ` - ${record[OverallFailureCode]}`) ||
            '';
          return `Failed${failureCode}`;
        } else {
          // Success formatting
          return 'Successful';
        }
      }
    },
  },
  {
    title: <span className="dashboard-data-header">{AUTHENTICATOR}</span>,
    csvTitle: AUTHENTICATOR,
    key: 'authenticator',
    render(_value, record, _index) {
      const factors =
        ('authenticator' in record && record['authenticator']) || '';

      if (factors && factors !== NotImplemented) {
        return factors;
      } else {
        let factorTypes = 'factor1-type' in record && record['factor1-type'];
        if (!factorTypes) {
          return '';
        }
        const factor2 = 'factor2-type' in record && record['factor2-type'];
        if (factor2) {
          factorTypes = `${factorTypes} + ${factor2}`;
        }
        return factorTypes;
      }
    },
  },
  {
    title: <span className="dashboard-data-header">{ENDPOINT}</span>,
    csvTitle: ENDPOINT,
    dataIndex: 'endpoint',
    key: 'endpoint',
  },
];

// Configuration for modal details Column names and values
export const dashboardSSOAuthnsColumns: Array<
  TableCsvColumn<MappedDashboardData>
> = [
  {
    title: <span className="dashboard-data-header">{TIMESTAMP}</span>,
    csvTitle: TIMESTAMP,
    key: OccurredAtTimestampColumn,
    render(_value, record, _index) {
      const timestamp = ('timestamp' in record && record['timestamp']) || '';
      if (timestamp && timestamp !== NotImplemented) {
        return timestamp;
      } else {
        return (
          (OccurredAtTimestampColumn in record &&
            record[OccurredAtTimestampColumn]) ||
          ''
        );
      }
    },
  },
  {
    title: <span className="dashboard-data-header">{USER}</span>,
    csvTitle: USER,
    key: 'user',
    render(_value, record, _index) {
      const user = ('user' in record && record['user']) || '';
      if (user && user !== NotImplemented) {
        return user;
      } else {
        const displayName =
          USER_DISPLAY_NAME in record && record[USER_DISPLAY_NAME];

        if (!displayName) {
          return '';
        }
        const username = 'user-upn' in record ? ` (${record['user-upn']})` : '';
        return `${displayName}${username}`;
      }
    },
  },
  {
    title: <span className="dashboard-data-header">{ACTIVITY}</span>,
    csvTitle: ACTIVITY,
    key: 'activity',
    dataIndex: 'activity',
  },
  {
    title: <span className="dashboard-data-header">{RESULT}</span>,
    csvTitle: RESULT,
    key: 'result',
    render(_value, record, _index) {
      const result = ('result' in record && record['result']) || '';
      if (result && result !== NotImplemented) {
        return result;
      } else {
        const overallResult =
          'overall-result-type' in record && record['overall-result-type'];
        if (!overallResult) {
          return '';
        }
        if (overallResult === 'failure') {
          // Failure formatting
          const failureCode =
            (OverallFailureCode in record &&
              record[OverallFailureCode] &&
              ` - ${record[OverallFailureCode]}`) ||
            '';
          return `Failed${failureCode}`;
        } else {
          // Success formatting
          return 'Successful';
        }
      }
    },
  },
  {
    title: <span className="dashboard-data-header">{ENDPOINT}</span>,
    csvTitle: ENDPOINT,
    dataIndex: 'endpoint',
    key: 'endpoint',
  },
];

export const dashboardDetailsColumns: Array<
  ColumnType<Partial<MappedDashboardData>> & {
    csvTitle: string;
  }
> = [
  {
    title: <span className="dashboard-data-header">{TIMESTAMP}</span>,
    csvTitle: TIMESTAMP,
    dataIndex: 'timestamp',
    key: 'timestamp',
  },
  {
    title: <span className="dashboard-data-header">{USER}</span>,
    csvTitle: USER,
    key: 'user',
    dataIndex: 'user',
  },
  {
    title: <span className="dashboard-data-header">{ACTIVITY}</span>,
    csvTitle: ACTIVITY,
    dataIndex: 'activity',
    key: 'activity',
  },
  {
    title: <span className="dashboard-data-header">{AUTHENTICATOR}</span>,
    csvTitle: AUTHENTICATOR,
    key: 'authenticator',
    dataIndex: 'authenticator',
  },
];

export const dashboardUniqueUserWidgetColumns: Array<
  TableCsvColumn<MappedDashboardData>
> = [
  {
    title: <span className="dashboard-data-header">{USER}</span>,
    csvTitle: USER,
    key: 'user',
    render(_value, record, _index) {
      const user = ('user' in record && record['user']) || '';
      if (user && user !== NotImplemented) {
        return user;
      } else {
        const displayName =
          'user-display-name' in record && record['user-display-name'];

        if (!displayName) {
          return '';
        }
        const username = 'user-upn' in record ? ` (${record['user-upn']})` : '';
        return `${displayName}${username}`;
      }
    },
  },
  {
    title: <span className="dashboard-data-header">{AAD_GROUP_NAMES}</span>,
    csvTitle: AAD_GROUP_NAMES,
    key: 'aad-group-names',
    dataIndex: 'aad-group-names',
    render(value, _record, _index) {
      return (value as string) || 'N/A';
    },
  },
];

export const dashboardDetailsMauWidget: Array<
  ColumnType<Partial<MappedDashboardData>> & {
    csvTitle: string;
  }
> = [
  {
    title: <span className="dashboard-data-header">{USER}</span>,
    csvTitle: USER,
    key: 'user',
    dataIndex: 'user',
  },
];
